import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ForgotPassword } from '@asecrmc/common/core/models/forgot-password-data';
import { ForgotPasswordMapper } from '@asecrmc/common/core/services/mappers/forgot-password.mapper';
import { ForgotPasswordResponseDto } from '@asecrmc/common/core/services/mappers/dto/forgot-password.dto';
import { ResetPasswordRequestData } from '@asecrmc/common/core/models/reset-password-data';
import { ResetPasswordMapper } from '@asecrmc/common/core/services/mappers/reset-password.mapper';

import { Login } from '../models/login-data';
import { User } from '../models/user';
import { routePaths } from '../utils/route-paths';

import { AppConfigService } from './app-config.service';
import { CurrentUserService } from './current-user.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { AuthDto } from './mappers/dto/auth.dto';
import { LoginMapper } from './mappers/login.mapper';

/** Perform auth operations. */
@Injectable({ providedIn: 'root' })
export class AuthService {
	private readonly loginApiUrl = new URL('auth/', this.appConfig.apiUrl).toString();

	public constructor(
		private readonly router: Router,
		private readonly httpClient: HttpClient,
		private readonly loginMapper: LoginMapper,
		private readonly appConfig: AppConfigService,
		private readonly appErrorMapper: AppErrorMapper,
		private readonly currentUserService: CurrentUserService,
		private readonly forgotPasswordMapper: ForgotPasswordMapper,
		private readonly resetPasswordMapper: ResetPasswordMapper,
	) {
	}

	/**
	 * Login a user.
	 * @param data Login data.
	 */
	public login(data: Login): Observable<User> {
		const body = this.loginMapper.toDto(data);
		const url = new URL('login/', this.loginApiUrl).toString();
		return this.httpClient.post<AuthDto>(url, body).pipe(
			switchMap(authDto => this.currentUserService.completeAuthorizationProcess(authDto)),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.loginMapper),
		);
	}

	/**
	 * Forgot password request.
	 * @param data Forgot password data.
	 */
	public forgotPassword(data: ForgotPassword): Observable<string> {
		const body = this.forgotPasswordMapper.toDto(data);
		const url = new URL('password-reset/', this.loginApiUrl).toString();
		return this.httpClient.post<ForgotPasswordResponseDto>(url, body).pipe(
			map(response => response.detail),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.forgotPasswordMapper),
		);
	}

	/**
	 * Reset password request.
	 * @param data Reset password data.
	 */
	public resetPassword(data: ResetPasswordRequestData): Observable<string> {
		const body = this.resetPasswordMapper.toDto(data);
		const url = new URL('password-reset-confirm/', this.loginApiUrl).toString();
		return this.httpClient.post<ForgotPasswordResponseDto>(url, body).pipe(
			map(response => response.detail),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.forgotPasswordMapper),
		);
	}

	/**
	 * Logout current user.
	 */
	public logout(): Observable<void> {
		const url = new URL('logout/', this.loginApiUrl).toString();
		return this.httpClient.post<void>(url, null).pipe(
			tap(() => this.router.navigate(routePaths.login)),
			switchMap(() => this.currentUserService.logout()),
			this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.forgotPasswordMapper),
		);
	}
}
