import { Injectable } from '@angular/core';
import { ForgotPassword } from '@asecrmc/common/core/models/forgot-password-data';

import { EntityValidationErrors } from '../../models/app-error';

import { ForgotPasswordDto } from './dto/forgot-password.dto';
import { extractErrorMessageByField, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error.mapper';

/** Mapper for forgot password entities. */
@Injectable({ providedIn: 'root' })
export class ForgotPasswordMapper
implements IMapperToDto<ForgotPasswordDto, ForgotPassword>, ValidationErrorMapper<ForgotPasswordDto, ForgotPassword> {
	/** @inheritdoc */
	public toDto(data: ForgotPassword): ForgotPasswordDto {
		return {
			email: data.email,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<ForgotPasswordDto>,
	): EntityValidationErrors<ForgotPassword> {
		return {
			email: extractErrorMessageByField(errorDto, 'email') ?? extractErrorMessageByField(errorDto, 'non_field_errors'),
		};
	}
}
